* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Arial, Helvetica, sans-serif;
}

// body {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   height: 100vh;
//   width: 100%;
// }

input {
  font-size: 11px;
  font-weight: normal;
  letter-spacing: 0;
  line-height: 20px !important;
  border: 1px solid #666666;
}

.mainContainer {
}

.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  border: 1px solid #000000;
  width: 701.6px;
  // margin-top: 10rem;
  margin-top: 10.625rem;
}

.top {
  display: flex;
  width: 100%;
  max-height: 184px;
  justify-content: space-between;
  position: relative;
}

.topfirstimage img,
.topsecondimage img {
  width: 100%;
  height: auto;
}

.topsecondimage {
  // display: flex;
  // justify-content: center;
  // align-items: center;
  position: absolute;
  right: 26px;
  top: 60px;
}

.middle {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 37px;
  width: 100%;
  border-top: 2px solid #000;
  border-bottom: 2px solid #000;
  background-color: #efb600;
}

.middleul {
  display: flex;
  gap: 5px;
}

.middleul li {
  list-style: none;
  font-size: 11px;
  font-weight: normal;
  color: #333333;
}

.middleul li a {
  text-decoration: none;
  font-size: 11px;
  font-weight: normal;
  letter-spacing: 0;
  color: #333333;
}

.middleul li a>.splash {
  font-size: 10px;
  position: relative;
  top: -1;
}

.bottom {
  position: relative;
  // max-height: 163px;
}

.bottom > div{
  max-height: 164px;
  overflow: hidden;
}

.bottom img {
  position: relative;
  top: 1px;
}

.formcontainer {
  width: 200px;
  height: 124px;
  position: absolute;
  top: 18px;
  left: 15px;
  // display: flex;
  // flex-direction: column;
  // gap: 6px;
  // border: 1px solid #000;
  background: url(../images/login-box.png) no-repeat;
  background-size: 100% 100%;
  padding: 11px 8px 8px 12.5px;
  border-bottom-right-radius: 10%;
}

.formelement {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 10px;
}

.formelement p {
  font-size: 11px;
  color: #666666;
  font-weight: normal;
  line-height: 10px;
  border: none;
}

.capcacode {
  display: flex;
  align-items: center;
  gap: 4px;
}

.capcacode input {
  width: 110px;
  height: 25px;
}

.capcacode button {
  width: 48px;
  height: 18px;
  background-color: royalblue;
  outline: none;
  border: none;
  margin-bottom: 4px;
  color: white;
  font-weight: bold;
  cursor: pointer;
  font-size: 11px;
}

.refreshcode {
  cursor: pointer;
  position: relative;
  top: -1px;
}

.capcha_design {
  background: #fffdfb;
  border: 2px solid #ff7588;
  border-color: #ff7b85;
  display: inline-block;
  padding: 1px;
  font-size: 17px;
  // color: #267031;
  color: #3d753c;
  font-style: italic;
  user-select: none;
  font-family: Arial, Helvetica, sans-serif;
  max-width: 90px;
  max-height: 24px;
  min-width: 90px;
  min-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: -2px;
}

.copyright {
  display: flex;
  justify-content: center;
  color: #808080;
  line-height: 50px;
  font-size: 10px;
  letter-spacing: 0.5px;
  padding-top: 2px;
}

.error {
  color: #c81e1e;
  font-size: 12px;
  line-height: 1.4;
  margin-top: 10px;
}

.usernametext {
  text-transform: uppercase;
}

#submit-button:disabled {
  background-color: gray;
  color: #fff;
  cursor: not-allowed;
}

.login-box input[type="text"],
.login-box input[type="password"] {
  border: 1px solid #666666 !important;
  color: #666666 !important;
  font-family: Arial, Helvetica, sans-serif !important;
  font-size: 11px !important;
  font-weight: normal !important;
  letter-spacing: 0 !important;
  text-decoration: none !important;
  vertical-align: middle !important;
  width: 81.6px !important;
  height: 21.6px !important;
  padding: 0 !important;
  line-height: 20px !important;
  left: -27px;
  position: relative;
}