// ContactForm.scss
.contact-form {
    max-width: 600px;
    margin: 2rem auto;
    // padding: 2rem;

    .margin-top-0{
      .srv-validation-message{
        margin-top: 0;
        margin-left: 0;
        margin-bottom: 20px;
      }
    }
  
    h2 {
      color: #333;
      margin-bottom: 1.5rem;
    }
  
    .contact-type {
      margin-bottom: 1.5rem;
  
      label {
        margin-right: 2rem;
        display: inline-flex;
        align-items: center;
        
        input[type="radio"] {
          margin-right: 0.5rem;
        }
      }
    }
  
    .form-group {
      margin-bottom: 1rem;
      
      label {
        display: block;
        margin-bottom: 0.5rem;
        color: #333;
      }
  
      input,
      textarea {
        width: 100%;
        padding: 0.5rem;
        border: 1px solid #ccc;
        border-radius: 4px;
        
        &:focus {
          outline: none;
          border-color: #666;
        }
      }
  
      textarea {
        min-height: 100px;
        resize: vertical;
      }
    }
  
    .captcha {
      display: flex;
      align-items: center;
      gap: 1rem;
  
      input {
        width: 150px;
      }
  
      .captcha-code {
        padding: 0.5rem 1rem;
        background: #f5f5f5;
        border: 1px solid #ddd;
        border-radius: 4px;
        font-family: monospace;
        color: #666;
      }
  
      .refresh-captcha {
        background: #eee;
        border: 1px solid #ccc;
        border-radius: 4px;
        padding: 0.5rem;
        cursor: pointer;
        
        &:hover {
          background: #ddd;
        }
      }
    }
  
    .submit-btn {
      margin-top: 1rem;
      padding: 0.75rem 1.5rem;
      background: #dc2626;
      color: white;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      
      &:hover {
        background: #b91c1c;
      }
    }
  }