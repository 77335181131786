* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Arial, Helvetica, sans-serif;
  }
  
  .dashboardContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .layout {
    display: flex;
    flex-direction: column;
    position: relative;
  }
  
  .firstlayer {
    background-color: black;
    height: 55px;
  }
  
  .secondlayer {
    background-color: #990000;
    height: 23px;
  }
  
  .middleul,
  .bottomul {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    height: 100%;
  }
  
  .bold {
    font-weight: 700;
  }
  
  a {
    cursor: pointer;
  }
  
  .middleul li {
    list-style: none;
    font-size: 13px;
    font-weight: normal;
    color: #ffffff;
    cursor: pointer;
  }
  
  .thirdlayer {
    background-color: #f7b200;
    padding: 5px 10px;
  }
  
  .bottomul li {
    font-weight: normal;
    font-size: 13px;
    color: #320000;
    list-style: none;
  }
  
  .logo {
    position: absolute;
    top: 15px;
    left: 10px;
  }
  
  .bottomMainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: auto;
    gap: 10px;
    max-width: 1100px;
  }
  
  .bottomtopSection {
    display: flex;
    width: 95%;
    justify-content: space-between;
  }
  
  .refreshbtn {
    background: #1e90ff;
    color: #fff;
    padding: 4px;
    font-size: 14px;
    outline: none;
    cursor: pointer;
    border: none;
  }
  
  .bottomText {
    display: flex;
    justify-content: space-between;
  }
  
  .bottomtopTextSection {
    width: 100%;
  }
  
  .bottomtopTextSection p {
    font-size: 12px;
    font-weight: normal;
    padding-top: 4px;
  }
  
  .headingpara {
    font-size: 13px;
    font-weight: 600;
  }
  
  .highlightText {
    color: #0000ff;
    font-size: 14px !important;
  }
  
  .managePoint {
    display: flex;
    flex-direction: column;
    width: 650px;
    border: 1px solid black;
    padding: 2px;
    margin: 0px 10px 10px 10px;
  }
  
  .managePointContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    background: #990000;
    color: white;
    height: 25px;
    font-size: 12px;
  }
  
  .managePoint form {
    width: 100%;
    background-color: #dcdcdc;
  }
  
  .formelement {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 10px;
    position: relative;
  }
  
  .formelement p {
    font-size: 13px;
    color: #666666;
    font-weight: normal;
    line-height: 10px;
    border: none;
  }
  
  .tableContainerform {
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 50%;
    margin: auto 0;
    padding: 15px 10px;
    gap: 4px;
  }
  
  .formelementbutton {
    display: flex;
    width: 100%;
    margin-top: 6px;
    justify-content: end;
  }
  
  .svgelement,
  .svgelementslaheye {
    position: absolute;
    right: 5px;
    top: 4px;
    cursor: pointer;
  }

  .error_content{
    width: 650px;
  }
  