.draw_details_section {
  // width: 1070px;
  width: auto;
  display: flex;
  &:first-child {
    padding-top: 10px;
  }
  .fun_roulette {
    margin-left: 10px;
    margin-top: 10px;
    // width: 350px;
    width: auto;
    max-height: 27rem;
    float: left;
    display: block;
    float: left;
    height: auto;
    margin: 0 4px 30px 0;
    padding: 0;
    position: relative;
    h1 {
      margin-bottom: 5px;
    }

    .fun_sec1 {
      background: #a27b3a;
      color: black;
      font-weight: 100;
      font-weight: 400;
      padding: 5px;
      font-size: 18px;
      font-weight: bold;
      unicode-bidi: isolate;
      padding: 10px 7px;
    }
  }
}

.table_td_span,
th span {
  color: #000000 !important;
  font-weight: bold;
}

.draw_details_container {
  display: block;
}

.publish_table {
  thead {
    tr {
      th {
        padding-top: 0;
        padding-bottom: 0;
        text-align: center !important;
        font-weight: 700;
      }
    }
  }
  tbody {
    tr {
      border: 1px solid #000 !important;
      color: #666;
      line-height: 100% !important;
      font-size: 12px !important;
      padding-left: 5px !important;
      padding-right: 5px !important;
    }
  }
}

.data-table {
  overflow: auto;
  max-height: 400px;
}
