
.header {
  display: flex;
  flex-direction: column;
  background-color: #000; // black background for the first section
  min-width: 1130px;
  &__logo {
    padding: 10px;

    img {
      width: 50px; // Adjust this according to your logo size
      height: auto;
    }
  }

  &__nav {
    // background-color: #b80000; // red background for the navigation section
    // background-color: #990000
    background-color: #990000; // red background for the navigation section
    color: white;
    display: flex;
    justify-content: center;
    max-height: 23px;
    .splash{
      position: relative;
      top: -2px;
    }

    ul {
      display: flex;
      list-style: none;
      margin: 0;
      padding: 5px 0;
      align-items: center;

      li {
        cursor: pointer;
        font-size: 13px;
        margin-right: 5px;
      }
    }
  }

  &__actions {
    // background-color: #ffcc00; // yellow background for the action section
    background-color: #F7B200; // yellow background for the action section
    color: black;
    display: flex;
    justify-content: center;
    // max-height: 21px;
    align-items: center;

    .splash{
      font-size: 12px;
    }
    .fw_bold{
      font-weight: 700;
    }

    ul {
      display: flex;
      gap: 5px;
      list-style: none;
      margin: 0;
      padding: 5px 0 5px 220px;
      justify-content: center;
      align-items: center;
      // flex-wrap: wrap;
      min-width: 1130px;

      li {
        cursor: pointer;
        font-size: 13px;
        letter-spacing: -0.2px;
      a{
        cursor: pointer;
        font-size: 13px;
        letter-spacing: -0.2px;
        color: #000000;
        text-decoration: none;
      }
      }
    }
  }
}

.header__logo {
  padding-top: 27px;
  img {
    z-index: 103;
    position: absolute;
    width:120px;
    height:90px;
    top: 14px;
    left: 16px;
  }
}


@media(max-width:1140px){
  .header__actions ul{
    padding-left: 110px;
  }
}

@media(max-width:1040px){
  .header__actions ul{
    padding-left: 160px;
  }
}