* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    font-family: Arial, Helvetica, sans-serif;
  }
  .dasboardContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .layout {
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .firstlayer {
    background-color: black;
    height: 55px;
  }
  .secondlayer {
    background-color: #990000;
    height: 23px;
  }
  .middleul,
  .bottomul {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    height: 100%;
  }
  .bold {
    font-weight: 700;
  }
  a {
    cursor: pointer;
  }
  .middleul li {
    list-style: none;
    font-size: 13px;
    font-weight: normal;
    color: #ffffff;
    cursor: pointer;
  }
  .thirdlayer {
    background-color: #f7b200;
    padding: 5px 10px;
  }
  .bottomul li {
    font-weight: normal;
    font-size: 13px;
    color: #320000;
    list-style: none;
  }
  .logo {
    position: absolute;
    top: 15px;
    left: 10px;
  }
  .bottomMainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: auto;
    gap: 10px;
    max-width: 1100px;
  }
  .bottomtopSection {
    display: flex;
    width: 95%;
    justify-content: space-between;
  }
  .refreshbtn {
    background: #1e90ff;
    color: #fff;
    padding: 4px;
    font-size: 14px;
    outline: none;
    cursor: pointer;
    border: none;
  }
  .bottomText {
    display: flex;
    justify-content: space-between;
  }
  .bottomtopTextSection {
    width: 100%;
  }
  .bottomtopTextSection p {
    font-size: 12px;
    font-weight: normal;
    padding-top: 4px;
  }

  .highlightText {
    color: #0000ff;
    font-size: 14px !important;
  }
  .managePoint {
    display: flex;
    flex-direction: column;
    width: 650px;
    border: 1px solid black;
    padding: 2px;
    margin: 0px 10px 10px 10px;
  }
  .managePointContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    background: #990000;
    color: white;
    height: 25px;
    font-size: 12px;
  }
  .managePoint form {
    width: 100%;
    background-color: #dcdcdc;
  }
  .formelement {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 10px;
    position: relative;
    width: 100%;
  }
  .formelement p {
    font-size: 13px;
    color: #666666;
    font-weight: normal;
    line-height: 10px;
    border: none;
  }
  .tableContainerform {
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 50%;
    margin: auto 0px;
    padding: 15px 10px;
    gap: 7px;
    position: relative;
  }
  .formelementbutton {
    display: flex;
    width: 100%;
    gap: 10px;
    margin-top: 6px;
    justify-content: start;
  }
  .svgelement,
  .svgelementslaheye {
    position: absolute;
    right: 5px;
    top: 0px;
    cursor: pointer;
    svg{
      font-size: 20px;
    }
  }
  .headingpara {
    font-size: 13px;
    font-weight: 600;
    padding-left: 5px;
  }
  .membertype {
    position: relative;
  }
  .membertype a {
    width: 100%;
    position: absolute;
    right: -390px;
    font-size: 13px;
    color: #0000ff;
  }
  .formelement a {
    font-size: 12px;
    color: #0000ff;
    line-height: 10px;
    text-decoration: underline;
  }
  .dob {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 6px;
  }
  .termscheckbox {
    width: 15px;
    height: 15px;
  }
  .profileuploader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 150px;
    position: absolute;
    right: -290px;
    border: 1px dashed black;
  }
  .fileinput {
    position: absolute;
    bottom: -60px;
    left: 0px;
    overflow: hidden;
  }
  .fileinput button {
    margin-top: 10px;
  }
  .pinpasswordmain {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
  }
  .pincontainer {
    display: flex;
    flex-direction: column;
    gap: 1px;
    border: 1px solid #dcdcdc;
    padding: 2px;
    width: 600px;
  }
  @media (max-width: 399px) {
    .pincontainer {
      width: 600px;
    }
  }
  .refreshbtn {
    background: #1e90ff;
    color: #fff;
    padding: 5px;
    font-size: 14px;
    outline: none;
    cursor: pointer;
    border: none;
  }
  .headingtable {
    display: flex;
    justify-content: center;
    background: #dcdcdc;
    gap: 10px;
    padding: 2px;
  }
  .table {
    width: 100%;
    height: 200px;
    display: flex;
    flex-direction: column;
    // gap: 10px;
    overflow-y: auto;
    /* padding: 0px 10px 0px 0px; */
  }
  tr {
    font-size: 15px;
    color: #666666;
    font-weight: 400;
  }
  .table thead tr {
    background: #f5f5f5;
    width: 100%;
    display: grid;
    padding: 6px;
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .table thead tr th {
    font-size: 12px;
    font-weight: 100 !important;
  }
  .table tbody {
    padding-top: 10px;
  }
  .table tbody tr {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    // background-color: #999999;
    justify-content: center;
    align-items: center;
  }
  .table tbody tr td {
    justify-self: center;
  }
  #newRow {
    background-color: white !important;
    padding: 3px;
  }