* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    font-family: Arial, Helvetica, sans-serif;
  }
  .dasboardContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .layout {
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .firstlayer {
    background-color: black;
    height: 55px;
    width: 100%;
  }
  .secondlayer {
    background-color: #990000;
    height: 23px;
    width: 100%;
  }
  .middleul,
  .bottomul {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    height: 100%;
  }
  .bold {
    font-weight: 700;
  }
  a {
    cursor: pointer;
  }
  .middleul li {
    list-style: none;
    font-size: 13px;
    font-weight: normal;
    color: #ffffff;
    cursor: pointer;
    position: relative;
    top: -1px;
  }
  .thirdlayer {
    background-color: #f7b200;
    padding: 5px 10px;
    width: 100%;
  }
  .bottomul li {
    font-weight: normal;
    font-size: 13px;
    color: #320000;
    list-style: none;
  }
  .logo {
    position: absolute;
    top: 15px;
    left: 10px;
  }
  .bottomMainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // width: 100%;
    width: 1070px;
    margin: auto;
    gap: 10px;
    max-width: 1070px;
  }
  .bottomtopSection {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  .bottomText {
    display: flex;
    justify-content: space-between;
    padding-top: 3px;
  }
  .bottomtopTextSection {
    width: 100%;
    margin: 10px 0 0 0;
  }
  .bottomtopTextSection p {
    font-size: 12px;
    font-weight: normal;
    padding-top: 1px;
  }

  .bottomtopSection .color_light{
    color: #666666;
  }

  .highlightText {
    color: #0000ff;
    font-size: 14px !important;
    padding-top: 3px !important;
  }
  .bottomImage {
    display: flex;
    width: 100%;
    padding-top: 10px;
  }
  .contentContainer {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  .point_transfer{
    background: #fff !important;
  }
  .dashboard_play{
    min-width: 1000px;
  }
  .tableContainerform{
    max-width: 260px;
  }
  .tableContainerform .formelementbutton{
    justify-content: end;
  }
  .managePoint {
    display: flex;
    flex-direction: column;
    width: 650px;
    border: 1px solid #bfbfbf;
    padding: 1px;
    margin: 0px 10px 10px 10px;
  }
  .managePointContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    background: #990000;
    color: white;
    height: 25px;
    font-size: 12px;
    margin: 0;
  }
  .radiobtn {
    display: flex;
    align-items: center;
  }
  .radiobtnContainer {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    font-size: 12px;
    margin-right: 50px;
  }
  .tableContainer {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 5px;
  }
  .table thead tr {
    background: #f5f5f5;
    width: 100%;
    display: grid;
    grid-template-columns: 50px 140px 70px 50px;
  }
  .table tbody tr{
    display: grid;
    grid-template-columns: 50px 140px 70px 50px;
  }
  .table thead tr th{
    font-weight: 100 !important;
  }
  .table tbody tr th{
    justify-self: flex-start;
  }
  .refreshbtncontainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #dcdcdc;
    padding: 3px 5px;
  }
  .refreshbtn {
    background: #1e90ff;
    color: #fff;
    padding: 4px;
    font-size: 14px;
    outline: none;
    cursor: pointer;
    border: none;
  }
  input[type="checkout"] {
    cursor: pointer;
  }
  .table {
    width: 100%;
    height: 130px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }
  tr {
    font-size: 13px;
    color: #666666;
    font-weight: 400;
    text-align: center;
  }
  .tablecontainerleft {
    position: relative;
    /* height: 200px;
    overflow-y: auto; */
  }
  .tablefooter {
    position: absolute;
    bottom: 0px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 4px 10px;
    background: #dcdcdc;
  }
  .checkboxContainer {
    display: flex;
    align-items: center;
    gap: 4px;
  }
  .text {
    font-size: 13px;
    line-height: 24px;
    color: #666666;
  }
  .headingpara {
    font-size: 13px;
    font-weight: 600;
  }
  .formelement {
    display: grid;
    grid-template-columns: 100px 150px;
    gap: 10px;
    align-items: center;
    position: relative;
    padding-bottom: 3.4px;
  }
  .formelement p {
    font-size: 13px;
    color: #666666;
    font-weight: normal;
    line-height: 10px;
    border: none;
    font-size: 11px;
    letter-spacing: 0;
    text-decoration: none;
  }
  .tableContainerform {
    display: flex;
    flex-direction: column;
    align-items: center;
    // width: 55%;
    width: 98%;
    margin: auto;
    padding: 5px 0px 15px 0;
    gap: 4px;
  }
  .formelementbutton {
    display: flex;
    width: 100%;
    align-items: center;
  }
  .formelementbutton p {
    color: #990000;
    font-weight: 700;
    font-size: 14px;
  }
  .custom-button {
    cursor: pointer;
  }
  td {
    margin-left: 10px; /* Adjust the value as needed */
}
.error{
  color: #FF0000 !important;
  font-size: 12px !important;
  font-weight: 100;
  position:absolute;
  right: -165px;
  text-align: left;
}
.account-error{
right: -133px;
}
.amount-error{
right: -133px;
}
#trans-msg{
  color: #990000 !important;
}
#cancel-btn{
  margin-right: 50px;
}